<template>
  <full-page-gradient class="credit-check-failed" color="blue">
    <!-- Credit check failed header -->
    <mobile-full-page-wrapper show-arrow arrow-color="white">
      <v-layout justify-center class="no-grow">
        <v-flex xs11>
          <typography type="h3"
            >Thank you for your {{ brand }} application.</typography
          >
        </v-flex>
      </v-layout>
      <v-layout class="no-grow" justify-center mb-5>
        <v-flex xs10 md9 lg8>
          <typography :type="$vuetify.breakpoint.xsOnly ? 'body' : 'h5'" light>
            You have requested a callback. One of our friendly customer service
            reps will call you back within two working days. Cheers.
          </typography>
        </v-flex>
      </v-layout>
    </mobile-full-page-wrapper>

    <mobile-full-page-wrapper>
      <v-layout fill-height column justify-space-between>
        <v-flex class="no-grow">
          <!-- Back to home button -->
          <v-layout justify-center>
            <v-flex xs10 sm6>
              <round-button @click="onHomeClick">Back to home</round-button>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </mobile-full-page-wrapper>
  </full-page-gradient>
</template>

<script>
import FullPageGradient from '@/components/templates/FullPageGradient.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { HOME } from '@/constants/routes';
import { mapGetters } from 'vuex';

export default {
  components: {
    Typography,
    FullPageGradient,
    RoundButton,
    MobileFullPageWrapper
  },
  methods: {
    onHomeClick() {
      window.location.href = HOME;
    }
  },
  computed: {
    ...mapGetters({
      brand: 'saver/brand'
    })
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.no-grow {
  flex-grow: 0;
}

.offer {
  color: $orange;
}

.offer-title {
  color: #7771ce;
}

.reasons {
  text-align: left;
}
</style>
